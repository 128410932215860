import axios from "axios";
import { useState, useContext, useMemo, useRef, useEffect, useCallback } from "react";
import { AxiosContext } from "../contexts/AxiosContext";
import { AuthHeadersProvider } from "../providers/AuthHeadersProvider";

export const useAxios = <T>(url: string, method: string, payload?: Record<string, unknown>) => {
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);

    const contextInstance = useContext(AxiosContext);

    const instance = useMemo(() => {
        return contextInstance || axios;
    }, [contextInstance]);

    const controllerRef = useRef(new AbortController());

    const cancel = () => { 
        controllerRef.current.abort();
    };

    const headers = AuthHeadersProvider();
    const getRequest = useCallback(async () => {
        try {
            const response = await instance.request({
                headers,
                signal: controllerRef.current.signal,
                data: payload,
                method,
                url,
            });

            if (response) {
                setData(response.data);
            }

        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoaded(true);
        }
    }, [headers, instance, method, payload, url])

    useEffect(() => {
        getRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return { cancel, data, error, loaded };
};