import {useNavigate} from "react-router-dom";
import {useStorageContext} from "../../contexts/StorageContext";
import {useAxios} from "../../hooks/useAxios";
import {useEffect} from "react";
import Loader from "../../components/common/Loader/Loader";
import {IGetSettings} from "../../models/api.interface";
import {RouteConstants} from "../../routes/routes-constant";


export const CheckPage = () => {

  // TODO: unify API calls
  const {data: settings, loaded: settingsLoaded, error} = useAxios<IGetSettings>(
    "/api/kyc/get-settings/",
    "GET"
  );


  const {saveSettings} = useStorageContext();
  const navigate = useNavigate();

  const allowedSteps = ["id_verification", "face_photo"];

  useEffect(() => {

    if (settings) {
      const requiredSteps = settings.data.required_steps;
      const nextSteps = settings.data.next_steps;
      var path = "";
      var stepsToValidation = requiredSteps.length > 0 ? requiredSteps : nextSteps;

      path = stepsToValidation.includes("id_verification")
        ? RouteConstants.ID_VERIFICATION_PATH
        : RouteConstants.FACE_PHOTO_PATH;

      saveSettings(settings.data);
      navigate(path);

      const requiredMissed = allowedSteps.every(s => !settings.data.required_steps.includes(s));
      const nextMissed = allowedSteps.every(s => !settings.data.next_steps.includes(s));

      if (requiredMissed && nextMissed) {
        navigate(RouteConstants.FINAL_PAGE_ERROR_PATH, {state: {message: "simpleMessage"}});
      }
    }

    if (settings?.data.has_pending_approval) {
      navigate(RouteConstants.FINAL_PAGE_ERROR_PATH, {state: {message: "pendingApproval"}});
    }

    if (error) {
      navigate(RouteConstants.FINAL_PAGE_ERROR_PATH, {state: {message: "simpleMessage"}});
    }
  });

  if (!settingsLoaded) {
    return <Loader/>;
  }

  return <>
  </>
};
