import {useNavigate} from "react-router-dom";
import {useStorageContext} from "../../contexts/StorageContext";
import {RouteConstants} from "../../routes/routes-constant";
import {useCallback, useEffect} from "react";
import Loader from "../../components/common/Loader/Loader";
import {setDataAction} from "../../api";

export const SendDataPage = () => {
  const {faceSasUrl, frontSasUrl, backSasUrl, document} = useStorageContext();
  const navigate = useNavigate();

  const dontSetData = sessionStorage.getItem('dontSetData');

  const payload = useCallback(() => {
    const id_verification = {
      back_id_scan_url: backSasUrl,
      front_id_scan_url: frontSasUrl,
      document_type: document,
    };

    const face_photo = {
      face_photo_url: faceSasUrl,
    };

    return {
      face_photo: faceSasUrl ? face_photo : undefined,
      id_verification: backSasUrl && frontSasUrl ? id_verification : undefined,
    };

  }, [backSasUrl, frontSasUrl, document, faceSasUrl]);

  useEffect(() => {
    if (!dontSetData) {
      setDataAction(payload()).then((response) => {
        if (response) {
          navigate(RouteConstants.FINAL_PAGE_SUCCESS_PATH, {replace: true});
        }
      }).catch((error) => {
        if (error) {
          navigate(RouteConstants.FINAL_PAGE_ERROR_PATH, {
            replace: true,
            state: {message: "sendDataError"}
          });
        }
      })
    } else {
      navigate(RouteConstants.FINAL_PAGE_SUCCESS_PATH, {replace: true});
    }
  }, [dontSetData, navigate, payload]);

  return <Loader/>;
};
