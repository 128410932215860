import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import style from "./SelectDocument.module.css";
import CustomButton from "../common/CustomButton/CustomButton";
import { useStorageContext } from "../../contexts/StorageContext";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../routes/routes-constant";
import { useTranslation } from "react-i18next";




const SelectDocument = () => {

  const { document, saveDocumentType } = useStorageContext();
  
  const navigate = useNavigate();
  const { t } = useTranslation();

  const documents = [
    { documentType: "1", document: "Driver License" },
    { documentType: "3", document: "Passport Card" },
    { documentType: "6", document: "Green Card" },
    { documentType: "7", document: "International ID" },
  ];

  const handleChange = (event: { target: { value: any } }) => {
    saveDocumentType(event.target.value);
  };

  const onClickNext = () => {
    navigate(RouteConstants.ID_VERIFICATION_FRONT_PATH);
  }

  return (
    <Box className={style.mainBox}>
      <Typography
        fontWeight={600}
        fontSize={"22px"}
        textAlign={"center"}
        marginTop={"32px"}
        color={"#5965ED"}
      >
        ID SCANNING
      </Typography>
      <Box className={style.container}>
        <Box className={style.formBox}>
          <Typography
            fontWeight={500}
            fontSize={"20px"}
            textAlign={"center"}
            marginTop={"40px"}
            marginBottom={"32px"}
            color={"#DEE0FB"}
          >
            Select which document type you want to use
          </Typography>
          <FormControl fullWidth>
            <Select
            displayEmpty
            renderValue={
              document !== "" ? undefined : () => <span>Nothing selected</span>
            }
              onChange={handleChange}
              value={document}
              IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
              variant="outlined"
            >
              {documents.map((step) => (
                <MenuItem key={step.documentType} value={step.documentType}>
                  {step.document}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={style.buttonBox}>
          <CustomButton onClick={onClickNext} disabled={!document} width={"101px"} height={"44px"} variant="contained">
          {t("buttons.next")}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};
export default SelectDocument;
