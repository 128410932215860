import React from "react";
import { Box, Typography } from "@mui/material";
import style from './Final.module.css'
import { useTranslation } from "react-i18next";


const Success = () => {

    const { t } = useTranslation();

    return (
        <Box className={style.mainBox}>
            <Typography textTransform={'uppercase'} color={'#5965ED'} fontWeight={600} fontSize={'22px'} textAlign={'center'}>
                {t("finalPage.successHeading")}
            </Typography>
            <Typography marginTop={'32px'} textAlign={'center'} fontWeight={400} fontSize={'16px'}>
            {t("finalPage.waitToConfirm")}
            </Typography>
            <Typography marginTop={'32px'} textAlign={'center'} fontWeight={500} fontSize={'20px'} color={'#5965ED'}>
            {t("finalPage.thankYou")}
            </Typography>
        </Box>
    );
};

export default Success;