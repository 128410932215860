import { encodeCredentials } from "../helpers";

export const AuthHeadersProvider = () => {

   const token = sessionStorage.getItem('token');

   let headers;

   const session = sessionStorage.getItem("session");
   const clientId = `${process.env.REACT_APP_CLIENT_ID}`;
   const clientSecret = `${process.env.REACT_APP_CLIENT_SECRET}`;

   if (token) {
      headers = {
         "Content-Type": "application/json;charset=UTF-8",
         Accept: "application/json",
         Authorization: `Bearer ${token}`,
         'X-Session-id': session
      }

   } else {
      headers = {
         "Content-Type": "application/json;charset=UTF-8",
         Accept: "application/json",
         Authorization: encodeCredentials(clientId, clientSecret)
      }
   }

   return headers;
};