import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader/Loader";
import { RouteConstants } from "../../routes/routes-constant";
import {setTokenRequest} from "../../api";


const InitialPage = () => {

  const [loaded, SetLoaded] = useState(false);
  const navigate = useNavigate(); 
  

  useEffect(() => {
    setTokenRequest({
      grant_type: "client_credentials",
      scopes: ["customer_info", "set_customer_kyc_data"],
    }).then((data: any) => {
      if (data) {
        sessionStorage.setItem("token", data.token);
        navigate(RouteConstants.CHECK_PAGE_PATH, { replace: true });

      }
    }).catch((error) => {
      if (error) {
        navigate(RouteConstants.FINAL_PAGE_ERROR_PATH, {state: {message: "simpleMessage"}});
        return;
      }
    }).finally(() => {
      SetLoaded(true);
    })
  }, [navigate])


  if (!loaded) return <Loader/>;


  return <Loader />;
};

export default InitialPage;
