import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useStorageContext } from "../../contexts/StorageContext";

export const CameraText = (props: {
  selfieMod: boolean;
  className?: string;
  photoType?: string;
}) => {
  const { selfieMod, className, photoType } = props;
  const { photoDone } = useStorageContext();
  const { t } = useTranslation();
  const idCardSideText = photoType === "frontSide" ? t("camera.front") : t("camera.back");
  const cameraHeading = selfieMod
    ? t("headings.facePhoto")
    : t("headings.idScanning");
    
  const idCardExplainText =
    <Trans
      i18nKey="camera.idScan"
      values={{ side: idCardSideText }}
      components={{ bold: <strong /> }}
    />;

  const idCardText = !photoDone ? idCardExplainText : t("camera.idScanDone");
  const facePhotoText = !photoDone
    ? t("camera.faceScan")
    : t("camera.faceScanDone");

  return (
    <Box className={className}>
      <Typography
        textAlign={"center"}
        color={"#5965ED"}
        fontWeight={600}
        fontSize={"22px"}
      >
        {cameraHeading}
      </Typography>
      <Typography
        lineHeight={"18px"}
        textAlign={"center"}
        fontWeight={400}
        fontSize={"16px"}
      >
        {!selfieMod ? idCardText : facePhotoText}
      </Typography>
    </Box>
  );
};
