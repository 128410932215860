import React from "react";
import { Box, Typography } from "@mui/material";
import style from './Final.module.css'
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error = () => {

    const location = useLocation();
    const { t } = useTranslation();

    const errorText = () => {
        if (location.state.message === "nonceExpired") {
            return t("finalPage.nonceError");
        }
        if (location.state.message === "pendingApproval") {
            return t("finalPage.pendingApproval");
        }
        if (!location.state.message || location.state.message === "simpleMessage") {
            return t("finalPage.error");
        }
        if (location.state.message === "sendDataError") {
            return t("finalPage.sendDataError");
        }
        if (location.state.message === "wentWrongError") {
            return t("finalPage.wentWrongError");
        }

    }

    return (
        <Box className={style.mainBox}>
            <Typography textTransform={'uppercase'} color={'#5965ED'} fontWeight={600} fontSize={'22px'} textAlign={'center'}>
                {t("finalPage.errorHeading")}
            </Typography>
            <Typography marginTop={'32px'} textAlign={'center'} fontWeight={400} fontSize={'16px'}>
                {errorText()}
            </Typography>
            <Typography marginTop={'32px'} textAlign={'center'} fontWeight={500} fontSize={'20px'} color={'#5965ED'}>
                <a className={style.link} href={'mailto:support@knightsglobal.tech'}>
                    {t("finalPage.support")}
                </a>
            </Typography>
        </Box>
    );
};

export default Error;