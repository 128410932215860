import {useNavigate} from "react-router-dom";
import Camera from "../../components/Camera/Camera";
import {useStorageContext} from "../../contexts/StorageContext";
import {Box} from "@mui/material";
import CustomButton from "../../components/common/CustomButton/CustomButton";
import {RouteConstants} from "../../routes/routes-constant";
import style from "./FacePhoto.module.css";
import {RefObject, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAxios} from "../../hooks/useAxios";
import {IGetSasUrl} from "../../models/api.interface";
import {uploadImage} from "../../azure-storage-blob";
import Loader from "../../components/common/Loader/Loader";

export const FacePhotoPage = () => {
  const {facePhoto, saveSasUrl, faceSasUrl} = useStorageContext();

  const navigate = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const takePhotoRef: RefObject<{ takePhoto(): void | null }> = useRef<{
    takePhoto(): void | null;
  }>(null);
  const retakePhotoRef: RefObject<{ retakePhoto(): void | null }> = useRef<{
    retakePhoto(): void | null;
  }>(null);

  useEffect(() => {
    if (sasUrl) {
      saveSasUrl(sasUrl.data.sas_url, "facePhoto");
    }
  });

  const onClickSubmit = useCallback(async () => {
    if (!facePhoto) return;
    try {
      setLoading(true);
      const response = await uploadImage(facePhoto, faceSasUrl!);
      if (response.status === 201) {
        navigate(RouteConstants.SET_DATA_PATH, {replace: true});
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  }, [facePhoto, faceSasUrl, navigate]);

  // TODO: unify API calls
  const {data: sasUrl, loaded} = useAxios<IGetSasUrl>(
    "/api/kyc/get-data-upload-url/",
    "GET"
  );

  const takePhoto = () => {
    takePhotoRef.current!.takePhoto();
  };

  const retakePhoto = () => {
    retakePhotoRef.current!.retakePhoto();
  };

  const renderButtons = () => {
    if (!facePhoto) {
      return (
        <div className={style.singleButton}>
          <CustomButton
            onClick={takePhoto}
            width={"175px"}
            height={"44px"}
            variant="contained"
          >
            {t("buttons.takePhoto")}
          </CustomButton>
        </div>
      );
    }
    return (
      <Box className={style.buttonsBox}>
        <CustomButton
          onClick={retakePhoto}
          width={"175px"}
          height={"44px"}
          variant="neutral"
        >
          {t("buttons.tryAgain")}
        </CustomButton>
        <CustomButton
          loading={loading}
          onClick={onClickSubmit}
          width={"175px"}
          height={"44px"}
          variant="contained"
        >
          {t("buttons.submit")}
        </CustomButton>
      </Box>
    );
  };

  return (
    <>
      {!loaded ? (
        <Loader/>
      ) : (
        <>
          <Camera
            withoutMask={false}
            retakeRef={retakePhotoRef}
            takePictureRef={takePhotoRef}
            photoType="facePhoto"
            image={facePhoto}
            selfieMod={true}
          />
          {renderButtons()}
        </>
      )}
    </>
  );
};
