import {Box} from "@mui/material";
import Camera from "../Camera/Camera";
import CustomButton from "../common/CustomButton/CustomButton";
import style from "./IdScanning.module.css";
import {useStorageContext} from "../../contexts/StorageContext";
import {useNavigate} from "react-router-dom";
import {RouteConstants} from "../../routes/routes-constant";
import {RefObject, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAxios} from "../../hooks/useAxios";
import {uploadImage} from "../../azure-storage-blob";
import {IGetSasUrl} from "../../models/api.interface";
import Loader from "../common/Loader/Loader";

export const CameraFrontSide = () => {
  const {frontPhoto, frontSasUrl, saveSasUrl, photoDoneState, savePhoto} =
    useStorageContext();

  const navigate = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState(false);
  const takePhotoRef: RefObject<{ takePhoto(): void | null }> = useRef<{
    takePhoto(): void | null;
  }>(null);
  const retakePhotoRef: RefObject<{ retakePhoto(): void | null }> = useRef<{
    retakePhoto(): void | null;
  }>(null);

  useEffect(() => {
    if (sasUrl) {
      saveSasUrl(sasUrl.data.sas_url, "frontSide");
    }
  });

  const onClickNext = useCallback(async () => {
    if (!frontPhoto) return;
    try {
      setLoading(true);
      const response = await uploadImage(frontPhoto, frontSasUrl!);
      if (response.status === 201) {
        navigate(RouteConstants.ID_VERIFICATION_BACK_PATH, {replace: true});
        photoDoneState(false);
        setPhotoUploaded(false)
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  }, [frontPhoto, frontSasUrl, navigate, photoDoneState]);

  // TODO: unify API calls
  const {data: sasUrl, loaded} = useAxios<IGetSasUrl>(
    `/api/kyc/get-data-upload-url/`,
    "GET"
  );

  const uploadPhoto = (event: any) => {
    const img: Blob = event.target.files[0];
    setPhotoUploaded(true)
    photoDoneState(true);
    savePhoto(img, "frontSide");
  };

  const takePhoto = () => {
    takePhotoRef.current!.takePhoto();
  };

  const retakePhoto = () => {
    setPhotoUploaded(false);
    retakePhotoRef.current!.retakePhoto();
  };

  const renderButtons = () => {
    if (!frontPhoto) {
      return (
        <Box className={style.buttonsBox}>
          <CustomButton width={"175px"} height={"44px"} variant="neutral">
            <label htmlFor="uploadFront">{t("buttons.upload")}</label>
            <input
              hidden
              id="uploadFront"
              type="file"
              name="myImage"
              accept="image/jpg"
              onChange={uploadPhoto}
            />
          </CustomButton>

          <CustomButton
            width={"175px"}
            height={"44px"}
            variant="contained"
            onClick={takePhoto}
          >
            {t("buttons.takePhoto")}
          </CustomButton>
        </Box>
      );
    }
    return (
      <Box className={style.buttonsBox}>
        <CustomButton
          onClick={retakePhoto}
          width={"175px"}
          height={"44px"}
          variant="neutral"
        >
          {t("buttons.tryAgain")}
        </CustomButton>
        <CustomButton
          onClick={onClickNext}
          loading={loading}
          width={"175px"}
          height={"44px"}
          variant="contained"
        >
          {t("buttons.next")}
        </CustomButton>
      </Box>
    );
  };

  return (
    <>
      {!loaded ? (
        <Loader/>
      ) : (
        <>
          <Camera
            withoutMask={photoUploaded}
            retakeRef={retakePhotoRef}
            takePictureRef={takePhotoRef}
            photoType="frontSide"
            image={frontPhoto}
            selfieMod={false}
          />
          {renderButtons()}
        </>
      )}
    </>
  );
};
