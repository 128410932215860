import {useEffect} from "react";
import style from "./Camera.module.css";
import {orientationType, paramsInterface, windowSizeType} from "./Camera";

export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

interface PropTypesInterface {
  onUpdate: (params: paramsInterface) =>  void;
  orientation: orientationType;
  windowParams: windowSizeType
}


export const CameraFrame = (props: PropTypesInterface) => {
  const {onUpdate, orientation, windowParams} = props
  const {windowHeight, windowWidth} = windowParams;
  let canvas: HTMLCanvasElement | null;

  // const [canvasUpdatedParams, SetCanvasUpdated] =
  //     useState<CanvasParams>({width: windowWidth, height: windowHeight})

  useEffect(
    () => {
      draw(windowWidth, windowHeight);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowHeight, windowHeight, orientation]
  );

  // useEffect(() => {
  //   SetCanvasUpdated({width: windowWidth, height: windowHeight});
  // }, [windowHeight, windowWidth]);

  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     SetCanvasUpdated({width: window.outerWidth, height: window.outerHeight});
  //   });
  //
  //   return () => window.removeEventListener('resize', () => {
  //     SetCanvasUpdated({width: window.outerWidth, height: window.outerHeight});
  //   });
  // }, [])


  const draw = (width: number, height: number) => {
    if (!canvas) return;
    const maskContext = canvas.getContext("2d");
    const frameContext = canvas.getContext("2d");

    if(!maskContext || !frameContext) return;

    canvas.width = width;
    canvas.height = height;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const cardAspectRatio = 31 / 21;

    const w = clamp(canvas.width - canvas.width * 0.17, 0, 340);
    const h = w / cardAspectRatio;
    const lWidth = orientation === 'horizontal' ? w : h;
    const lHeight = orientation === 'horizontal' ? h : w

    onUpdate({width: Math.ceil(lWidth), height: Math.ceil(lHeight),
      left: centerX - lWidth / 2, top: centerY - lHeight / 2})

    maskContext.globalAlpha = 0.7;
    maskContext.fillStyle = "#121421";
    maskContext.fillRect(0, 0, width, height);
    maskContext.clearRect(centerX - lWidth / 2, centerY - lHeight / 2, lWidth, lHeight);

    frameContext.globalAlpha = 1;
    frameContext.strokeStyle = "#fff";
    frameContext.lineWidth = 5.33;

    frameContext.beginPath();
    frameContext.moveTo(centerX - lWidth / 2, centerY - lHeight / 2.4);
    frameContext.lineTo(centerX - lWidth / 2, centerY - lHeight / 2);
    frameContext.lineTo(centerX - lWidth / 2.3, centerY - lHeight / 2);
    frameContext.stroke();

    frameContext.beginPath();
    frameContext.moveTo(centerX - lWidth / 2, centerY - -lHeight / 2.4);
    frameContext.lineTo(centerX - lWidth / 2, centerY - -lHeight / 2);
    frameContext.lineTo(centerX - lWidth / 2.3, centerY - -lHeight / 2);
    frameContext.stroke();

    frameContext.beginPath();
    frameContext.moveTo(centerX - -lWidth / 2, centerY - -lHeight / 2.4);
    frameContext.lineTo(centerX - -lWidth / 2, centerY - -lHeight / 2);
    frameContext.lineTo(centerX - -lWidth / 2.3, centerY - -lHeight / 2);
    frameContext.stroke();

    frameContext.beginPath();
    frameContext.moveTo(centerX - -lWidth / 2, centerY - lHeight / 2.4);
    frameContext.lineTo(centerX - -lWidth / 2, centerY - lHeight / 2);
    frameContext.lineTo(centerX - -lWidth / 2.3, centerY - lHeight / 2);
    frameContext.stroke();
  };

  return <canvas ref={(c) => (canvas = c)} className={style.canvasMask} />;
};
