import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { DotsStepper } from "../common/DotsStepper/DotsStepper";

const Layout = () => {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box component={"main"}
           flexGrow={1}
           display={"flex"}
           flexDirection={"column"}
           maxWidth={"1630px"}
           width={"100%"}
           m={"0 auto"}>
        <Outlet />
      </Box>
      <DotsStepper/>
    </Box>
  );
};

export default Layout;