import { createTheme } from "@mui/material/styles";


export const theme = createTheme({
  typography: {
    "fontFamily": "'Montserrat', sans-serif",
    allVariants: {
      color: "#ffffff",
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
      fontSize: "16px",
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: "#5965ED",
    },
    secondary: {
      main: '#FAB665',
    },
    action: {
      disabledBackground: 'rgba(89, 101, 237, 0.5)',
      disabled: 'rgba(255, 255, 255, 0.5)'
    }

  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "neutral" },
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              color: "#FFFFFF",
            },
            "&:active": {
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              color: "#FFFFFF",
            }
          },
        } as any,
      ],
    },
    MuiFormControl: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            color: "#979AB2",
            "& label.Mui-focused": {
              color: "#979AB2",
            },
            "& .Mui-error label": {
              color: "#E9757E!important",
              borderColor: "#E9757E!important",
            },
            "& input": {
              color: "#979AB2",
              backgroundColor: "#FFFFFF0D",
              paddingLeft: 16,
              paddingTop: 12,
              paddingBottom: 12,
            },
            "& input::placeholder": {
              color: "#979AB2",
            },
            "& .MuiSvgIcon-root": {
              color: "#979AB2",
            },
            "& .MuiInputLabel-root": {
              color: "#979AB2",
            },
            "& .MuiInput-underline:after": {
              color: "transparent",
            },
            "& .MuiInput-underline:before": {
              color: "#979AB2",
            },
            "& .MuiInputBase-root": {
              color: "#979AB2",
              backgroundColor: "#FFFFFF0D",
              "& fieldset": {
                borderColor: "#667080",
                borderRadius: 8,
              },
              "&:hover fieldset": {
                borderColor: "#667080",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#667080",
              },
              "&.Mui-error fieldset": {
                borderColor: "#E9757E!important",
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {},
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          marginTop: '40px',
          backgroundColor: "transparent",
          width: '100%',
          padding: 0
        },
        dot: {
          backgroundColor: '#5965ED',
          width: '16px',
          height: '16px',
          opacity: 0.5,
          margin: '4px',
        },
        dotActive: {
          backgroundColor: '#5965ED',
          width: '16px',
          height: '16px',
          opacity: 1
        },
        dots: {
          margin: 'auto',
          alignSelf: 'center',
          alignItems: 'center',
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          marginTop: "8px",
          backgroundColor: "#FFFFFF0D",
          border: "1px solid #667080",
          borderRadius: 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#DEE0FB",
          "&.Mui-selected": {
            backgroundColor: "transparent"
          },
        },
      },
    },
  }

})