import axios, { AxiosInstance, AxiosInterceptorOptions, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { createContext, useRef, useEffect, ReactNode } from "react";

export const AxiosContext = createContext<AxiosInstance | null>(null);

export interface InterceptorTypeProvider<T> {
  onFulfilled: ((value: T) => T | Promise<T>) | null,
  onRejected?: any,
  options?: AxiosInterceptorOptions
}

interface IAxiosProvider {
  config: {
    baseURL: string;
    headers?: Record<string, string>;
  };
  requestInterceptors?: InterceptorTypeProvider<InternalAxiosRequestConfig>[];
  responseInterceptors?: InterceptorTypeProvider<AxiosResponse>[];
  children: React.ReactElement<ReactNode>;
}

export const AxiosInstanceProvider = ({
  config,
  requestInterceptors,
  responseInterceptors,
  children,
}: IAxiosProvider) => {
  const instanceRef = useRef(axios.create(config));

  useEffect(() => {
    requestInterceptors?.forEach((interceptor) => {
      instanceRef.current.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected, interceptor.options);
    });
    responseInterceptors?.forEach((interceptor) => {
      instanceRef.current.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected, interceptor.options);
    });
  }, [requestInterceptors, responseInterceptors]);

  return (
    <AxiosContext.Provider value={instanceRef.current}>
      {children}
    </AxiosContext.Provider>
  );
};
