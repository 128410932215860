import React from "react";
import { RouteConstants } from "../../routes/routes-constant";
import Error from "../../components/Final/Error";
import Success from "../../components/Final/Success";
import { useLocation } from "react-router-dom";

const FinalPage = () => {
  const location = useLocation();

  if (location.pathname === RouteConstants.FINAL_PAGE_ERROR_PATH) {
    return <Error />;
  }

  return <Success />;
};

export default FinalPage;
