import MobileStepper from "@mui/material/MobileStepper";
import style from "./Stepper.module.css";
import { useStorageContext } from "../../../contexts/StorageContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RouteConstants } from "../../../routes/routes-constant";
import { exceptionPaths } from "../../../helpers";

export const DotsStepper = () => {
  const { settings } = useStorageContext();
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const nextSteps = settings?.next_steps;
  const requiredSteps = settings?.required_steps

  useEffect(() => {
    if (location.pathname === RouteConstants.ID_VERIFICATION_PATH) {
      setActiveStep(0);
    }
    if (location.pathname === RouteConstants.ID_VERIFICATION_FRONT_PATH) {
      setActiveStep(1);
    }
    if (location.pathname === RouteConstants.ID_VERIFICATION_BACK_PATH) {
      setActiveStep(2);
    }
    if (location.pathname === RouteConstants.FACE_PHOTO_PATH) {
      setActiveStep(3);
    }
  }, [location.pathname]);

  const setSteps = () => {
    if (
      nextSteps?.includes("id_verification") &&
      !nextSteps?.includes("face_photo") && !requiredSteps?.includes("face_photo")
    ) {
      return 3;
    }

    return 4;
  };

  const renderStepper = () => {
    if (
      nextSteps?.includes("face_photo") ||
      exceptionPaths.some((path) => location.pathname === path)
    ) {
      return null;
    }

    return (
      <MobileStepper
        className={style.position}
        variant="dots"
        steps={setSteps()}
        position="static"
        activeStep={activeStep}
        nextButton={null}
        backButton={null}
      />
    );
  };

  return <>{renderStepper()}</>;
};
