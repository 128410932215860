import React from "react";
import RoutesProvider from "./routes/RoutesProvider";
import { ThemeProvider } from "@mui/material";
import { theme } from "./utils/theme";
import { AxiosInstanceProvider } from "./contexts/AxiosContext";
import { StorageProvider } from "./contexts/StorageContext";

function App() {
  return (
    <StorageProvider>
      <AxiosInstanceProvider
        config={{
          baseURL: `${process.env.REACT_APP_SERVER_URL}`,
        }}
      >
        <ThemeProvider theme={theme}>
          <RoutesProvider />
        </ThemeProvider>
      </AxiosInstanceProvider>
    </StorageProvider>
  );
}

export default App;
