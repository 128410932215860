import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { RouteConstants } from "./routes-constant";
import { mainRoutes } from "./main-routes";
import Layout from "../components/Layout";
import { nonceExpired } from "../helpers";

const RoutesProvider = () => {
  const searchParams = useLocation().search;

  const params = new URLSearchParams(searchParams);
  const nonce = params.get("nonce");
  const session = params.get("session");
  const dontSetData = params.get('dontSetData');

  const defaultRedirect = () => {
    if (dontSetData) sessionStorage.setItem('dontSetData', dontSetData);
    if (!nonce || !session || nonceExpired(nonce)) {
      return (
        <Navigate
          to={RouteConstants.FINAL_PAGE_ERROR_PATH}
          state={{ message: "nonceExpired" }}
        />
      );
    } else {
      sessionStorage.setItem("session", session);
      return <Navigate to={RouteConstants.INITIAL_PATH} />;
    }
  };

  const renderRoutes = () => {
    return mainRoutes.map((r) => {
      return (
        <Route key={r.id} path={r.path} index={r.index} element={r.component} />
      );
    });
  };

  return (
    <Routes>
      <Route path={"/"} element={<Layout />}>
        {renderRoutes()}
        <Route index element={defaultRedirect()} />
      </Route>
    </Routes>
  );
};

export default RoutesProvider;
