export class RouteConstants {
    static readonly ID_VERIFICATION = "id-verification";
    static readonly ID_VERIFICATION_FRONT = "front-side";
    static readonly ID_VERIFICATION_BACK = "back-side";
    static readonly FINAL_PAGE_SUCCESS = "success";
    static readonly FINAL_PAGE_ERROR = "failed";
    static readonly INITIAL = "token";
    static readonly FACE_PHOTO = "face-photo";
    static readonly CHECK_PAGE = "verification-check";
    static readonly SET_DATA = "set-data";


    static readonly SET_DATA_PATH = `/${RouteConstants.SET_DATA}`;
    static readonly CHECK_PAGE_PATH = `/${RouteConstants.CHECK_PAGE}`;
    static readonly FACE_PHOTO_PATH = `/${RouteConstants.FACE_PHOTO}`;
    static readonly INITIAL_PATH = `/${RouteConstants.INITIAL}`;
    static readonly ID_VERIFICATION_PATH = `/${RouteConstants.ID_VERIFICATION}`;
    static readonly ID_VERIFICATION_FRONT_PATH = `/${RouteConstants.ID_VERIFICATION_FRONT}`;
    static readonly ID_VERIFICATION_BACK_PATH = `/${RouteConstants.ID_VERIFICATION_BACK}`;
    static readonly FINAL_PAGE_SUCCESS_PATH = `/${RouteConstants.FINAL_PAGE_SUCCESS}`;
    static readonly FINAL_PAGE_ERROR_PATH = `/${RouteConstants.FINAL_PAGE_ERROR}`;
  }
  
