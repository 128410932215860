import { RouteConstants } from "./routes-constant";
import { IRoute } from "../models/route.interface";
import SelectPage from "../pages/SelectPage/SelectPage";
import FinalPage from "../pages/FinalPage/FinalPage";
import InitialPage from "../pages/InitialPage/InitialPage";
import { FacePhotoPage } from "../pages/FacePhotoPage/FacePhotoPage";
import { CameraBackSide } from "../components/IdScanning/CameraBackSide";
import { CameraFrontSide } from "../components/IdScanning/CameraFrontSide";
import { CheckPage } from "../pages/CheckPage/CheckPage";
import { SendDataPage } from "../pages/SendDataPage/SendDataPage";

export const mainRoutes: IRoute[] = [
  {
    id: RouteConstants.SET_DATA,
    path: RouteConstants.SET_DATA_PATH,
    component: <SendDataPage />,
  },
  {
    id: RouteConstants.FACE_PHOTO,
    path: RouteConstants.FACE_PHOTO_PATH,
    component: <FacePhotoPage />,
  },
  {
    id: RouteConstants.CHECK_PAGE,
    path: RouteConstants.CHECK_PAGE_PATH,
    component: <CheckPage />,
  },
  {
    id: RouteConstants.INITIAL,
    path: RouteConstants.INITIAL_PATH,
    component: <InitialPage />,
  },
  {
    id: RouteConstants.ID_VERIFICATION,
    path: RouteConstants.ID_VERIFICATION_PATH,
    component: <SelectPage />,
  },
  {
    id: RouteConstants.ID_VERIFICATION_FRONT,
    path: RouteConstants.ID_VERIFICATION_FRONT_PATH,
    component: <CameraFrontSide/>
  },
  {
    id: RouteConstants.ID_VERIFICATION_BACK,
    path: RouteConstants.ID_VERIFICATION_BACK_PATH,
    component: <CameraBackSide/>
  },
  {
    id: RouteConstants.FINAL_PAGE_ERROR,
    path: RouteConstants.FINAL_PAGE_ERROR_PATH,
    component: <FinalPage />,
  },
  {
    id: RouteConstants.FINAL_PAGE_SUCCESS,
    path: RouteConstants.FINAL_PAGE_SUCCESS_PATH,
    component: <FinalPage />,
  },
];
