import React, {
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import style from "./Camera.module.css";
import {Box} from "@mui/material";
import {CameraText} from "./CameraText";
import {useStorageContext} from "../../contexts/StorageContext";
import {CameraFrame} from "./CameraFrame";
import {useNavigate} from "react-router-dom";
import {RouteConstants} from "../../routes/routes-constant";
import * as browserType from 'react-device-detect';

export interface paramsInterface {
  width: number | undefined,
  height: number | undefined,
  left?: number | undefined,
  top?: number | undefined,
}

export type windowSizeType = {
  windowWidth: number,
  windowHeight: number,
  orientation: 'album' | 'landscape',
}

export type orientationType = 'vertical' | 'horizontal';

const Camera = ({
  selfieMod,
  image,
  photoType,
  retakeRef,
  takePictureRef,
  withoutMask,
}: {
  withoutMask: boolean;
  selfieMod: boolean;
  image: Blob | null;
  photoType: string;
  retakeRef: RefObject<{ retakePhoto(): void | null }>;
  takePictureRef: RefObject<{ takePhoto(): void | null }>;
}) => {
  const {savePhoto, removePhoto, photoDoneState} = useStorageContext();

  const navigate = useNavigate();

  const [videoFrame, SetVideoFrame] = useState<paramsInterface>()
  const [windowSize, SetWindowSize] = useState<windowSizeType>({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    orientation: window.innerWidth < window.innerHeight ? 'album' : 'landscape'
  })

  const videoRef = useRef<HTMLVideoElement>();

  const {orientation} = windowSize

  let video: any;
  let canvas: HTMLCanvasElement | null;

  const resizeHandler = useCallback(() => {
    const windowWidth = browserType.isIOS ? window.innerWidth : window.outerWidth;
    const windowHeight = browserType.isIOS ? window.innerHeight : window.outerHeight;
    SetWindowSize({
      windowWidth,
      windowHeight,
      orientation: window.innerWidth < window.innerHeight ? 'album' : 'landscape'
    })
  }, [])


  useEffect(() => {
    window.addEventListener("resize", () => resizeHandler());

    return window.removeEventListener('resize', () => resizeHandler());
    // eslint-disable-next-line
  }, []);


  const onUpdateFrame = useCallback((params: paramsInterface) => {
    SetVideoFrame(params);
  }, [])

  useImperativeHandle(takePictureRef, () => ({
    takePhoto: () => {
      if (!canvas || !video) return;

      canvas.width = (videoFrame?.width || 0) * 3;
      canvas.height = (videoFrame?.height || 0) * 3;

      canvas
        .getContext("2d")!
        .drawImage(
          video,
          0,
          0,
          canvas.width,
          canvas.height,
        );
      (video.srcObject! as MediaStream)
        .getTracks()!
        .forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      try {
        canvas.toBlob((blob) => {
          if (!blob) {
            photoDoneState(false);
            navigate(RouteConstants.FINAL_PAGE_ERROR_PATH, {
              state: {message: "wentWrongError"},
            });
            return;
          }
          photoDoneState(true);
          savePhoto(blob, photoType);
        }, "image/jpg");
      } catch (error) {
        navigate(RouteConstants.FINAL_PAGE_ERROR_PATH, {
          state: {message: "wentWrongError"},
        });
        console.error(error);
      } finally {
        canvas.getContext("2d")?.clearRect(0, 0, canvas.width, canvas.height);
      }
    },
  }));

  useImperativeHandle(retakeRef, () => ({
    retakePhoto: () => {
      removePhoto(photoType);
      photoDoneState(false);
    },
  }));

  const facingMode = selfieMod ? "user" : "environment";

  const streamCamera = useMemo(() => async (video: HTMLVideoElement | null) => {

    if (video) {

      const param = {width: videoFrame?.width, height: videoFrame?.height || 220}

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: facingMode,
            ...param,
          },
        });

        if (video) {
          video.srcObject = stream;
        }
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line
  }, [orientation])


  const renderCamera = () => {

    return (
      <Box
        style={{
          position: 'absolute',
          top: videoFrame?.top,
          left: videoFrame?.left,
        }}
        ref={videoRef}
      >
        <canvas ref={(c) => (canvas = c)} className={style.hiddenCanvas}/>
        <video
          loop
          autoPlay
          playsInline
          muted
          style={{
            width: videoFrame?.width,
            height: videoFrame?.height,
            objectFit: 'cover',
          }}
          className={style.scanning}
          ref={(c) => {
            video = c;
            streamCamera(c);
          }}
          controls={false}
        ></video>
      </Box>
    );
  };

  const renderImage = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        height={videoFrame?.height}
        width={videoFrame?.width}
        top={videoFrame?.top}
        left={videoFrame?.left}
        position={'absolute'}
      >
        <img
          className={withoutMask ? style.uploadedImage : style.imageContainer}
          src={URL.createObjectURL(image!)}
          alt="confirmationImg"
        />
      </Box>
    );
  };

  return (
    <>
      <Box className={style.mainBox}>
        {!withoutMask
          ? <CameraFrame
            onUpdate={onUpdateFrame}
            orientation={orientation === 'album' ? 'vertical' : 'horizontal'}
            windowParams={windowSize}
          />
          : null
        }
        <CameraText
          photoType={photoType}
          selfieMod={selfieMod}
          className={style.textBox}
        />
        {!image ? renderCamera() : renderImage()}
      </Box>
    </>
  );
};
export default Camera;
