import { Buffer } from 'buffer';
import { RouteConstants } from '../routes/routes-constant';
import axios from "axios";
import {AuthHeadersProvider} from "../providers/AuthHeadersProvider";


export const encodeCredentials = (clientId: string, clientSecret: string) => {
    const buff = Buffer.from((clientId + ':' + clientSecret), "utf8");
    return buff.toString('base64');
}

export const nonceExpired = (nonce: string) => {
    const convertedNonce = Number(nonce);

    return convertedNonce < Date.now();
};

export const exceptionPaths = [
    RouteConstants.FINAL_PAGE_ERROR_PATH, RouteConstants.FINAL_PAGE_SUCCESS_PATH, RouteConstants.CHECK_PAGE_PATH, RouteConstants.INITIAL_PATH, RouteConstants.SET_DATA_PATH
];


export const makeAxiosPromise = (method: string, url: string, data?: object, params?: object, headers?: object) => {
    return {
        promise() {
            return new Promise((resolve, reject) => {
                const p = {
                    method,
                    url: `${process.env.REACT_APP_SERVER_URL}${url}`,
                    params,
                    data,
                    headers: AuthHeadersProvider() || {
                        'Content-Type': 'application/json',
                        ...headers,
                    },
                };
                axios(p)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) {
                            console.error(`cancel by token error while try to perform: ${method} ${url}`, error);
                            reject([{...error, isCancel: true}, null]);
                        } else {
                            console.error(`error while try to perform: ${method} ${url}`, error);
                            reject([{...error}, null]);
                        }
                    });
            });
        },
        promiseDebug() {
            return new Promise((resolve, reject) => {
                const p = {
                    method,
                    url: `${process.env.REACT_APP_SERVER_URL}${url}`,
                    params,
                    data,
                    headers: AuthHeadersProvider() || {
                        'Content-Type': 'application/json',
                    },
                };
                axios(p)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) {
                            console.error(`cancel by token error while try to perform: ${method} ${url}`, error);
                            reject([{...error, isCancel: true}, null]);
                        } else {
                            console.error(`error while try to perform: ${method} ${url}`, error);
                            reject([{...error}, null]);
                        }
                    });
            });
        },
    };
};

export const clearSessionStorage = () => {
    sessionStorage.clear();
}

