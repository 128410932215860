import axios from "axios";

export async function uploadImage(file: Blob, sasUrl: string) {

  const body = new FormData();
  body.set('file', file);
  
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Ms-Blob-Type': 'BlockBlob'
    }
  };

  return await axios.put(sasUrl, file, config);

}

