import { createContext, useContext, useState } from "react";
import { ISettings } from "../models/api.interface";

interface IStorageContext {
  savePhoto(photo: Blob, photoType: string): void;
  removeSettings(): void;
  saveSettings(data: ISettings): void;
  removePhoto(photoType: string): void;
  photoDoneState(state: boolean): void;
  saveDocumentType(document: string): void;
  saveSasUrl(sasUrl: string, photoType: string): void;
  document: string;
  photoDone: boolean;
  settings: ISettings | null;
  facePhoto: Blob | null;
  backPhoto: Blob | null;
  frontPhoto: Blob | null;
  frontSasUrl: string | null;
  backSasUrl: string | null;
  faceSasUrl: string | null;
}

const StorageContext = createContext<IStorageContext | null>(null);

const StorageProvider = (props: any) => {
  const [document, setDocument] = useState("");
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [frontPhoto, setFrontPhoto] = useState<Blob | null>(null);
  const [backPhoto, setBackPhoto] = useState<Blob | null>(null);
  const [facePhoto, setFacePhoto] = useState<Blob | null>(null);
  const [photoDone, setPhotoDone] = useState<boolean>(false);
  const [frontSasUrl, setFrontSasUrl] = useState<string | null>(null);
  const [backSasUrl, setBackSasUrl] = useState<string | null>(null);
  const [faceSasUrl, setFaceSasUrl] = useState<string | null>(null);

  const saveSettings = (data: ISettings) => {
    setSettings(data);
  };

  const removeSettings = () => {
    setSettings(null);
  };

  const saveSasUrl = (sasUrl: string, photoType: string) => {
    if (photoType === "frontSide") {
      setFrontSasUrl(sasUrl);
    }
    if (photoType === "backSide") {
      setBackSasUrl(sasUrl);
    }

    if (photoType === "facePhoto") {
      setFaceSasUrl(sasUrl);
    }
  }

  const saveDocumentType = (document: string) => {
    setDocument(document)
  }

  const photoDoneState = (state: boolean) => {
    setPhotoDone(state);
  };

  const removePhoto = (photoType: string) => {
    if (photoType === "frontSide") {
      setFrontPhoto(null);
    }
    if (photoType === "backSide") {
      setBackPhoto(null);
    }
    if (photoType === "facePhoto") {
      setFacePhoto(null);
    }
  };

  const savePhoto = (photo: Blob, photoType: string) => {
    if (photoType === "frontSide") {
      setFrontPhoto(photo);
    }
    if (photoType === "backSide") {
      setBackPhoto(photo);
    }

    if (photoType === "facePhoto") {
      setFacePhoto(photo);
    }
  };

  const storageData: IStorageContext = {
    saveSasUrl,
    frontSasUrl,
    backSasUrl,
    faceSasUrl,
    document,
    saveDocumentType,
    photoDone,
    photoDoneState,
    savePhoto,
    removePhoto,
    removeSettings,
    saveSettings,
    settings,
    facePhoto,
    backPhoto,
    frontPhoto,
  };

  return <StorageContext.Provider value={storageData} {...props} />;
};

function useStorageContext(): IStorageContext {
  return useContext(StorageContext)!;
}

export { StorageProvider, useStorageContext };
